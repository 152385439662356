<template>
  <div class="container">
    <Header v-if="isElectron" />
    <div class="cpt-index">
      <div class="index-body">
        <video class="login-bg" autoplay muted loop>
          <source src="https://mmc-crm.oss-cn-shenzhen.aliyuncs.com/prod/bg.68ac9db0.mp4" type="video/mp4" />
        </video>
        <div class="login-box">
          <div class="login-logo">
            <img
              src="@/assets/home/MSpaceLogo1.png"
              alt=""
              height="32px"
              width="32px"
            />
            <span>天目将</span>
          </div>
          <div class="login-box">
            <div v-if="loginMode === 'account_password'" class="i18n">
              <span v-hover>中文</span>
              <!-- <span>|</span>
              <span v-hover style="color: #1eb9fa">En</span> -->
            </div>

            <div
              class="form-box"
              :class="{ qrcodeStyle: loginMode === 'weixin_qrcode' }"
            >
              <!--   @tab-click="handleClick" -->
              <el-tabs v-model="activeName" class="tabs">
                <el-tab-pane label="账号密码" name="first">
                  <el-form
                    ref="login_form"
                    class="login-form"
                    :model="loginForm"
                    :rules="rules"
                    label-width="0px"
                    label-position="left"
                    @submit.native.prevent="handleLogin"
                  >
                    <el-card v-if="isElectron" shadow="always" class="dog-card">
                      {{
                        isDog
                          ? "已识别登录密钥，点击登录进入系统"
                          : "未检测到密钥，请插入加密狗！"
                      }}
                    </el-card>
                    <template v-if="loginMode === 'account_password'">
                      <!-- 用户名 -->
                      <el-form-item
                        v-if="!isElectron"
                        prop="username"
                        class="login-username"
                      >
                        <el-input
                          v-model.trim="loginForm.username"
                          placeholder="请输入用户名"
                        >
                          <i slot="prefix">
                            <div class="iconfont icon-yonghuming" />
                          </i>
                        </el-input>
                      </el-form-item>
                      <!-- 密码 -->
                      <el-form-item
                        v-if="!isElectron"
                        prop="password"
                        class="login-password"
                      >
                        <!-- <el-input name="passwordHide"
                        type="password"
                        style="display: none"></el-input> -->
                        <el-input
                          v-model.trim="loginForm.password"
                          show-password
                          type="password"
                          placeholder="请输入密码"
                          autocomplete="off"
                        >
                          <i slot="prefix">
                            <div class="iconfont icon-a-mima" />
                          </i>
                        </el-input>
                      </el-form-item>
                      <!-- 文本 -->
                      <div
                        class="login-tag"
                        :class="{
                          [`tag-color-true`]: tag_state,
                          [`tag-color-false`]: !tag_state,
                        }"
                      >
                        {{ tooptip }}
                      </div>
                    </template>
                    <!-- <template v-else-if="loginMode === 'weixin_qrcode'">
                  <div class="scan-qrcode">
                    <img class="bg" src="../../assets/home/qr-box.png" alt="" />
                    <div class="content">
                      <h3>微信登陆</h3>
                      <div id="WXlogin" ref="qrcode" class="code-img"></div>
                      <h4>打开微信扫一扫</h4>
                    </div>
                  </div>
                </template> -->
                    <div class="login-check">
                      <el-checkbox v-model="checked" text-color="#badfee"
                        >我已阅读并同意</el-checkbox
                      >
                      <div>
                        <span @click="pageState = 1">用户协议</span
                        ><span style="color: #badfee">和</span>
                        <span @click="pageState = 2">隐私保护</span>
                      </div>
                    </div>
                    <!-- 登录 -->
                    <el-button
                      v-if="loginMode === 'account_password'"
                      class="login_button"
                      type="primary"
                      native-type="submit"
                      :loading="loading"
                      >登 录</el-button
                    >
                  </el-form>
                </el-tab-pane>
                <el-tab-pane label="微信扫码" name="second">
                  <div v-if="wxToLogin">
                    <el-form
                      ref="wxLoginForm"
                      class="login-form"
                      :model="wxLoginForm"
                      :rules="rules"
                      label-width="0px"
                      label-position="left"
                      @submit.native.prevent="newBindWx(newWx)"
                    >
                      <div style="width: 100%; text-align: center; color: #fff">
                        还没有绑定账号，立即绑定？
                      </div>
                      <el-card
                        v-if="isElectron"
                        shadow="always"
                        class="dog-card"
                      >
                        {{
                          isDog
                            ? "已识别登录密钥，点击登录进入系统"
                            : "未检测到密钥，请插入加密狗！"
                        }}
                      </el-card>
                      <template v-if="loginMode === 'account_password'">
                        <!-- 用户名 -->
                        <el-form-item
                          v-if="!isElectron"
                          prop="username"
                          class="login-username"
                        >
                          <el-input
                            v-model.trim="wxLoginForm.username"
                            placeholder="请输入用户名"
                          >
                            <i slot="prefix">
                              <div class="iconfont icon-yonghuming" />
                            </i>
                          </el-input>
                        </el-form-item>
                        <!-- 密码 -->
                        <el-form-item
                          v-if="!isElectron"
                          prop="password"
                          class="login-password"
                        >
                          <!-- <el-input name="passwordHide"
                        type="password"
                        style="display: none"></el-input> -->
                          <el-input
                            v-model.trim="wxLoginForm.password"
                            show-password
                            type="password"
                            placeholder="请输入密码"
                            autocomplete="off"
                          >
                            <i slot="prefix">
                              <div class="iconfont icon-a-mima" />
                            </i>
                          </el-input>
                        </el-form-item>
                        <!-- 文本 -->
                        <div
                          class="login-tag"
                          :class="{
                            [`tag-color-true`]: tag_state,
                            [`tag-color-false`]: !tag_state,
                          }"
                        >
                          {{ tooptip }}
                        </div>
                      </template>
                      <div class="login-check">
                        <el-checkbox v-model="checked" text-color="#badfee"
                          >我已阅读并同意</el-checkbox
                        >
                        <div>
                          <span @click="pageState = 1">用户协议</span
                          ><span style="color: #badfee">和</span>
                          <span @click="pageState = 2">隐私保护</span>
                        </div>
                      </div>
                      <!-- 登录 -->
                      <el-button
                        v-if="loginMode === 'account_password'"
                        class="login_button"
                        type="primary"
                        native-type="submit"
                        :loading="loading"
                        size="mini"
                        >绑 定 并 登 录</el-button
                      >
                    </el-form>
                  </div>
                  <div v-else id="wx_login_container">
                    <div class="wx_login">
                      <div class="label" @click="handleClick">
                        请点击前往登陆页扫码
                      </div>
                      <div
                        title="点击前往登陆页扫码"
                        class="icon el-icon-position"
                        @click="handleClick"
                      />
                    </div>
                  </div>
                  <!-- <div v-else id="wx_login_container">
                    <div className="iframe" />
                    <iframe
                      :src="iframeUrl"
                      frameborder="0"
                      style="width: 300px; height: 300px"
                    ></iframe>
                  </div> -->
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
          <div class="copyright">
            <!-- Copyright ©2009-2024 浙江科比特创新科技有限公司 -->
          </div>
        </div>
      </div>
      <UserAgreement :page-state="pageState" @changeState="changeState" />
      <PrivacyAgreement :page-state="pageState" @changeState="changeState" />
    </div>
  </div>
</template>

<script>
import { LoginAPI, User } from "@/api";
import { mapMutations } from "vuex";
import UserAgreement from "@/components/login/user.vue";
import PrivacyAgreement from "@/components/login/privacy.vue";
import Header from "@/components/header";
import { Base64 } from "js-base64";
import qs from "qs";
// 路径转化二维码
import QRCode from "qrcodejs2";
const electron = window.electron;
const ipcRenderer = electron?.ipcRenderer;
const { VUE_APP_WX_URL } = process.env;
export default {
  name: "Login",
  components: {
    QRCode,
    Header,
    UserAgreement,
    PrivacyAgreement,
  },
  data() {
    const validatePassword = (rule, value, callback) => {
      if (!value || !value.trim()) {
        if (this.loginForm.username) {
          // this.tooptip = '请输入密码'
          // this.tag_state = true
          this.$message.warning("请输入密码!");
        } else {
          // this.tooptip = '请输入用户名'
          // this.tag_state = true
          this.$message.warning("请输入用户名!");
        }
      } else {
        callback();
      }
    };
    return {
      activeName: "first",
      loginForm: {
        username: "",
        password: "",
      },
      checked: true,
      loading: false,
      tooptip: "",
      tag_state: true,
      rules: {
        password: [
          { validator: validatePassword, trigger: ["blur", "change"] },
        ],
      },
      pageState: 0,
      isElectron: false,
      isDog: false,
      loginMode: "account_password",
      qrcodeImg: "",
      num: 0,
      // 微信是否需要绑定
      wxToLogin: false,
      newWx: null,
      wxLoginForm: {
        username: "mmc111",
        password: "123456",
      },
      iframeUrl: "",
    };
  },

  methods: {
    ...mapMutations(["SET_USERINFO", "SET_PERMISSION"]),
    indexFilter() {
      // 新对象
      const obj = {};
      const url = "http://localhost:8080/?id=2&type=1&age=20"; // 模拟url 地址
      const strList = url.split("&");
      strList.forEach((item) => {
        // eslint-disable-next-line prefer-destructuring
        obj[item.split("=")[0]] = item.split("=")[1];
      });
      return obj;
    },
    indexFilter(url) {
      // 新对象
      const obj = {};
      // const url = "http://localhost:8080/?id=2&type=1&age=20"; // 模拟url 地址
      const strList = url.split("&");
      strList.forEach((item) => {
        // eslint-disable-next-line prefer-destructuring
        obj[item.split("=")[0]] = item.split("=")[1];
      });
      return obj;
    },
    WXLogin() {
      let UrlOfWx = "https://open.weixin.qq.com/connect/qrconnect?";
      const customeStyle =
        "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyMDBweDt9Ci5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30g";
      const redirect = "https://tmj.mmcuav.cn/";
      const url = {
        self_redirect: false,
        id: "wx_login_container",
        appid: "wx0af16d5fb9ae561c", // 微信开放平台网站应用appid
        scope: "snsapi_login",
        redirect_uri: encodeURI(redirect), // 设置扫码成功后回调页面
        state: "onlineEdu",
        app_secret: "6ba6117ac22c545661eb7f8d9326138d",
        response_type: "code",
        style: "white",
        href: customeStyle,
        // href: customeStyle, // location.origin + "/css/wx_qrcode.css", //自定义微信二维码样式文件
      };
      for (const key in url) {
        UrlOfWx += `${key}=${url[key]}&`;
      }
      this.iframeUrl = UrlOfWx;
      console.log(UrlOfWx);
      // let xhr = new XMLHttpRequest();
      // let url = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js';
      // xhr.open("GET", url, true);

      // xhr.onload = function () { //请求成功
      //   const script = document.createElement("script");
      //   script.innerText = xhr.responseText;
      //   document.head.appendChild(script);
      //   const redirect = "https://tmj.mmcuav.cn/";
      //   // const customeStyle =
      //   //   "data:text/css;base64,Lnd4X3FyY29kZSBpZnJhbWUgeyB3aWR0aDogMTYwcHg7IGhlaWdodDogMTYwcHg7IG1hcmdpbjogMDsgcGFkZGluZzogMDsgfQ0KLmxvZ2luUGFuZWwgeyBtYXJnaW46MDsgcGFkZGluZzogMDsgfQ0KLmxvZ2luUGFuZWwgLnRpdGxlLCAubG9naW5QYW5lbCAuaW5mbyB7IGRpc3BsYXk6IG5vbmU7IH0NCi5pbXBvd2VyQm94IC5xcmNvZGUgeyBtYXJnaW46IDA7IHdpZHRoOiAxNjBweDsgYm9yZGVyOiBub25lOyB9";
      //   const customeStyle = "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyMDBweDt9Ci5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30g"
      //   const Login = new WxLogin({
      //     self_redirect: false,
      //     id: "wx_login_container",
      //     appid: "wx0af16d5fb9ae561c", // 微信开放平台网站应用appid
      //     scope: "snsapi_login",
      //     redirect_uri: encodeURI(redirect), // 设置扫码成功后回调页面
      //     state: "onlineEdu",
      //     app_secret: "6ba6117ac22c545661eb7f8d9326138d",
      //     response_type: "code",
      //     style: "white",
      //     href: customeStyle,
      //     // href: customeStyle, // location.origin + "/css/wx_qrcode.css", //自定义微信二维码样式文件
      //   });

      //   // 设置iframe标签可以进行跨域跳转
      //   const qrcodeBox = document.getElementById(
      //     "wx_login_container"
      //   )
      //   const iframes = qrcodeBox.getElementsByTagName("iframe");
      //   if (iframes.length) {
      //     const ifr = iframes[0];
      //     ifr.setAttribute(
      //       "sandbox",
      //       "allow-scripts allow-top-navigation allow-same-origin"
      //     );
      //   }
      // }
      // xhr.onerror = function () { //请求失败
      //   console.log('wx登录二维码请求失败!');
      // }

      // xhr.send(null);
      // 引入“微信内嵌二维码”脚本
      // const script = document.createElement("script");
      // script.src = "https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
      // document.head.appendChild(script);
      // const redirect = "https://tmj.mmcuav.cn/";
      // // const customeStyle =
      // //   "data:text/css;base64,Lnd4X3FyY29kZSBpZnJhbWUgeyB3aWR0aDogMTYwcHg7IGhlaWdodDogMTYwcHg7IG1hcmdpbjogMDsgcGFkZGluZzogMDsgfQ0KLmxvZ2luUGFuZWwgeyBtYXJnaW46MDsgcGFkZGluZzogMDsgfQ0KLmxvZ2luUGFuZWwgLnRpdGxlLCAubG9naW5QYW5lbCAuaW5mbyB7IGRpc3BsYXk6IG5vbmU7IH0NCi5pbXBvd2VyQm94IC5xcmNvZGUgeyBtYXJnaW46IDA7IHdpZHRoOiAxNjBweDsgYm9yZGVyOiBub25lOyB9";
      // const customeStyle = "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyMDBweDt9Ci5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30g"
      // const Login = new WxLogin({
      //   self_redirect: false,
      //   id: "wx_login_container",
      //   appid: "wx0af16d5fb9ae561c", // 微信开放平台网站应用appid
      //   scope: "snsapi_login",
      //   redirect_uri: encodeURI(redirect), // 设置扫码成功后回调页面
      //   state: "onlineEdu",
      //   app_secret: "6ba6117ac22c545661eb7f8d9326138d",
      //   response_type: "code",
      //   style: "white",
      //   href: customeStyle,
      //   // href: customeStyle, // location.origin + "/css/wx_qrcode.css", //自定义微信二维码样式文件
      // });

      // // 设置iframe标签可以进行跨域跳转
      // const qrcodeBox = document.getElementById(
      //   "wx_login_container"
      // )
      // const iframes = qrcodeBox.getElementsByTagName("iframe");
      // if (iframes.length) {
      //   const ifr = iframes[0];
      //   ifr.setAttribute(
      //     "sandbox",
      //     "allow-scripts allow-top-navigation allow-same-origin"
      //   );
      // }
    },
    async handleClick(tab, event) {
      if (this.activeName === "second") {
        window.location.href =
          "https://open.weixin.qq.com/connect/qrconnect?appid=wx0af16d5fb9ae561c&redirect_uri=http%3A%2F%2Ftmj.mmcuav.cn%2F&response_type=code&scope=snsapi_login&state=onlineEdu#wechat_redirect";
        this.activeName = "first";
      }
      //   console.log(tab,event);
      //   if (tab.name==="second") {
      //  let res =  await LoginAPI.getWxCodeImg()
      //  console.log(res);
      //   }
    },
    changeLoginMode(arg) {
      this.loginMode = arg === 1 ? "account_password" : "weixin_qrcode";
    },
    async handleLogin() {
      // ipcRenderer && ipcRenderer.send("sendMessage", "this is render")

      if (this.isElectron) {
        if (this.isDog) {
          LoginAPI.loginByUsbkey({ usbkey: this.dogKey }).then((resp) => {
            if (resp.code == 200) {
              if (resp.data) {
                // console.log('resp.data...'.resp)
                window.localStorage.setItem(
                  "user_info",
                  JSON.stringify(resp.data.data)
                );
                window.localStorage.setItem("tmj_token", resp.data.data.token);
                this.SET_USERINFO(resp.data); // 用户信息
                this.$message({
                  type: "success",
                  message: "登录成功",
                  duration: 1000,
                });
                this.timeShow();
                // this.$router.push("/");
              }
            } else if (resp.code == 403) {
              this.getDogKey();
              this.$message({
                type: "error",
                message: "登录失败，请检查加密狗是否正确插入",
                duration: 1000,
              });
            }
          });
        } else {
          this.$message({
            type: "info",
            message: "请检查加密狗是否正确插入",
            duration: 1000,
          });
        }
      } else {
        const param = {
          userAccount: this.loginForm.username,
          // password: window.btoa(this.loginForm.password),
          password: this.loginForm.password,
        };
        // this.$router.push("/");
        const that = this;
        if (this.checked) {
          this.$refs["login_form"].validate(async (validate) => {
            if (validate) {
              // that.tooptip = "已识别到USBKey，请输入加密密码…"
              this.loading = true;
              // LoginAPI.newLogin({ ...param })
              const forms = new FormData();
              forms.append("userAccount", this.loginForm.username);
              forms.append("password", Base64.encode(this.loginForm.password));
              try {
                const resp = await LoginAPI.newLogin(forms);
                if (resp.code == 200) {
                  if (resp.data) {
                    window.localStorage.setItem(
                      "user_info",
                      JSON.stringify(resp.data)
                    );
                    window.localStorage.setItem("tmj_token", resp.data.token);
                    this.SET_USERINFO(resp.data); // 用户信息
                    // 获取账户权限
                    const pms = await LoginAPI.permission(forms);
                    this.SET_PERMISSION(pms.data);
                    this.$message({
                      type: "success",
                      message: "登录成功",
                      duration: 1000,
                    });
                    this.timeShow();
                    this.$router.push("/");
                  }
                } else if (resp.code == 525) {
                  // this.tooptip = '密码错误，请重新输入'
                  // this.tag_state = false
                  this.$message({
                    type: "error",
                    message: resp.msg,
                    duration: 1000,
                  });
                } else if (resp.code == 405) {
                  this.$message({
                    type: "error",
                    message: "账号已过有效期，请联系管理员！",
                    duration: 1000,
                  });
                  this.tooptip = "";
                } else {
                  this.$message({
                    type: "error",
                    message: resp.msg,
                    duration: 1000,
                  });
                }
              } catch (e) {
              } finally {
                this.loading = false;
              }
            }
          });
        } else {
          const that = this;
          setTimeout(function () {
            that.$message({
              type: "warning",
              message: "请阅读协议！",
              duration: 1000,
            });
          }, 2000); // 一秒内不能重复点击
          // this.$message({
          //   type: "warning",
          //   message: "请阅读协议！",
          //   duration: 1000,
          // })
        }
      }
    },

    // 设置cookie
    setCookie(c_name, c_pwd, exdays) {
      var exdate = new Date(); // 获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); // 保存的天数
      // 字符串拼接cookie
      window.document.cookie =
        "mmc_tmj_userName" +
        "=" +
        c_name +
        ";path=/;expires=" +
        exdate.toGMTString();
      window.document.cookie =
        "mmc_tmj_userPwd" +
        "=" +
        window.btoa(c_pwd) +
        ";path=/;expires=" +
        exdate.toGMTString();
    },
    // 读取cookie
    getCookie: function () {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split("; "); // 这里显示的格式需要切割一下自己可输出看下
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split("="); // 再次切割
          // 判断查找相对应的值
          if (arr2[0] == "mmc_tmj_userName") {
            this.loginForm.username = arr2[1]; // 保存到保存数据的地方
          } else if (arr2[0] == "mmc_tmj_userPwd") {
            this.loginForm.password = Decrypt(arr2[1]);
          }
        }
      }
    },
    // 清除cookie
    clearCookie: function () {
      this.setCookie("", "", -1); // 修改2值都为空，天数为负1天就好了
    },

    changeState(state) {
      this.pageState = state;
    },
    uint8ArrayToString(fileData) {
      var dataString = "";
      for (var i = 0; i < fileData.length; i++) {
        dataString += String.fromCharCode(fileData[i]);
      }
      return dataString;
    },
    getBrowserIsElctron() {
      const userAgent = navigator.userAgent;
      const isElectron = userAgent.includes("Electron");
      this.isElectron = isElectron;
    },
    getOldDogKey() {
      try {
        const dllPath =
          process.env.NODE_ENV == "development"
            ? "electron/dog/Dog.dll"
            : "resources/electron/dog/Dog.dll";
        const ffi = require("ffi-napi");
        const customlibProp = ffi.Library(dllPath, {
          mmc_dog_login: ["int64", []],
          mmc_dog_read: ["char *", []],
        });

        // console.log(customlibProp.mmc_dog_login());

        const uint8ArrayBuffer = customlibProp.mmc_dog_read();
        const uint8Array = new Uint8Array(uint8ArrayBuffer.buffer);
        const newUint8Arry = uint8Array.slice(0, 128);

        let uint8String = new TextDecoder().decode(newUint8Arry);
        uint8String = uint8String.replace(/\x00/g, "");
        // console.log('旧加密狗：token-', uint8String)

        if (uint8String && uint8String.length > 0) {
          this.dogKey = uint8String;
          this.isDog = true;
        } else {
          this.isDog = false;
        }
      } catch (error) {
        this.isDog = false;
      }
    },
    getNewDogKey() {
      if (!ipcRenderer) {
        return;
      }
      ipcRenderer.send("onFindPort");

      ipcRenderer.on("ErrMsg", function (event, ErrMsg) {
        // console.log('新加密狗：', ErrMsg)
      });

      ipcRenderer.on("ErrMsgEx", function (event, ErrMsg, lasterror) {
        // console.log('新加密狗：', ErrMsg + lasterror.toString())
      });

      ipcRenderer.on("GetUSBKeyInfo", function (event, ID, Ver, VerEx) {
        // console.log('新加密狗：', 'GetUSBKeyInfo')
        // console.log(event, ID, Ver, VerEx)
        if (ID) {
          ipcRenderer.send("OnYReadStringBnt");
        }
      });

      ipcRenderer.on("Msg", function (event, Msg) {
        // console.log('新加密狗：', Msg)
      });
      ipcRenderer.on("TokenMsg", (event, Msg) => {
        // console.log('新加密狗：token-', Msg)
        // alert(Msg)
        if (Msg && Msg.length > 0) {
          this.dogKey = Msg;
          this.isDog = true;
        } else {
          this.isDog = false;
        }
      });
    },
    getDogKey() {
      this.getOldDogKey();
      this.getNewDogKey();
    },
    async bindWx() {
      const cate = this.indexFilter(window.location.search.replace("?", ""));
      try {
        const res = await User.wxLoginBind(cate);
        if (res.code === 200) {
          window.localStorage.setItem("user_info", JSON.stringify(res.data));
          window.localStorage.setItem("tmj_token", res.data.token);
          this.SET_USERINFO(res.data); // 用户信息
          // 获取账户权限
          const pms = await LoginAPI.permission();
          this.SET_PERMISSION(pms.data);
          if (pms.code === 200) {
            this.$message({
              type: "success",
              message: "登录成功",
              duration: 1000,
            });
            this.timeShow();
            this.$router.push("/");
            window.location.href = `${VUE_APP_WX_URL}/#/home`;
          }
        } else if (res.code === 402) {
          this.wxToLogin = true;
        } else if (res.code === 525) {
          this.$message.error(res.msg);
          window.location.href = `${VUE_APP_WX_URL}/#/login`;
        }
      } catch (e) {
        if (e.code === 500) {
          this.wxToLogin = true;
          this.newWx = e.data;
        }
      }
    },
    async newBindWx(data) {
      const res1 = await User.wxLoginHomeBind({
        ...data,
        userAccount: this.wxLoginForm.username,
        password: Base64.encode(this.wxLoginForm.password),
      });
      if (res1.code === 200) {
        window.localStorage.setItem("user_info", JSON.stringify(res1.data));
        window.localStorage.setItem("tmj_token", res1.data.token);
        this.SET_USERINFO(res1.data); // 用户信息
        // 获取账户权限
        const pms = await LoginAPI.permission();
        this.SET_PERMISSION(pms.data);
        if (pms.code === 200) {
          this.$message({
            type: "success",
            message: "登录成功",
            duration: 1000,
          });
          this.timeShow();
          this.$router.push("/");
          window.location.href = `${VUE_APP_WX_URL}/#/home`;
        }
      }
    },
    async LoginWx() {
      if (
        window.location.search.includes("code") &&
        window.location.search.includes("state")
      ) {
        this.activeName = "second";
        await this.bindWx();
      } else {
        setTimeout(() => {
          this.WXLogin();
        }, 300);
      }
    },
    configShow({ days, hours, minutes, seconds }) {
      this.$confirm(
        `您的账号有效期还剩下${days.toFixed(0)}天${hours.toFixed(
          0
        )}小时${minutes.toFixed(0)}分${seconds.toFixed(
          0
        )}秒,为了不影响您的工作，请及时联系平台研发制造商进行续约`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      );
    },
    getTimer(times) {
      const days = times / (1000 * 60 * 60 * 24);
      const hours = (times % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60);
      const minutes = (times % (1000 * 60 * 60)) / (1000 * 60);
      const seconds = (times % (1000 * 60)) / 1000;

      if (days >= 1) {
        this.Time = `${days.toFixed(0)}天`;
        if (days <= 7) {
          this.configShow({ days, hours, minutes, seconds });
        }
      } else if (days < 1 && hours >= 1) {
        this.Time = `${hours.toFixed(0)}小时`;
        this.configShow({ days, hours, minutes, seconds });
      } else if (hours < 1 && minutes >= 1) {
        this.Time = `${minutes.toFixed(0)}分钟`;
        this.configShow({ days, hours, minutes, seconds });
      } else if (minutes < 1 && seconds >= 1) {
        this.Time = `${seconds.toFixed(0)}秒`;
        this.configShow({ days, hours, minutes, seconds });
      }
    },
    async timeShow() {
      const data = JSON.parse(window.localStorage.getItem("user_info"));
      const res = await User.PersonalCenter({ id: data.id });
      console.log(res);
      if (res.code === 200) {
        const times = new Date(res.data.validityDate).getTime() - Date.now();
        this.getTimer(times);
      }
    },
  },
  mounted() {
    this.getBrowserIsElctron();
    this.getDogKey();
    this.LoginWx();
    // ipcRenderer && ipcRenderer.send("sendMessage", "this is render")
    // ipcRenderer && ipcRenderer.on("sendMain", (event, data) => {
    //   console.log(data)
    // })

    // if (this.loginForm.username) {
    //   this.tooptip = "已识别到USBKey，请输入加密密码…";
    // } else {
    //   this.tooptip = "请插入USBKey连接登录…";
    // }
  },
  created() {
    const _this = this;
    document.onkeydown = function () {
      var key = window.event.keyCode;
      if (key == 13) {
        _this.handleLogin();
      }
    };
    // this.qrcodeImg =
    //     'https://open.weixin.qq.com/connect/qrconnect?appid=wx0af16d5fb9ae561c&redirect_uri=http%3A%2F%2Fd3ew5gw.nat.ipyingshe.com%2F&response_type=code&scope=snsapi_login&state=onlineEdu#wechat_redirect',
    // LoginAPI.wxLogin().then((res) => {
    //   // console.log(res.data.redirect)
    //   // console.log(res.data.slice(9))
    //     this.qrcodeImg = res.data.slice(9)
    // }).catch((err)=> {
    //   console.log('微信登录失败',err)
    // })
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .cpt-index {
    position: relative;
    background-color: #141622;
    background-size: cover;
    font-family: Microsoft YaHei;
    width: 100%;
    height: 100%;
    .index-body {
      /* background: url('~@/assets/loginImage/BG.png') no-repeat; */
      /* background-size: 100% 100%; */
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.login-box {
  position: absolute;
  height: 600px;
  width: 960px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #000000;
  // background: #151b27;
  background-image: linear-gradient(
    172deg,
    rgba(83, 221, 255, 0.05) 0%,
    rgba(51, 166, 214, 0) 56%
  ); */
  background: url("~@/assets/uavApplications/login/login-bg.png") no-repeat;
  background-size: cover;
  border-radius: 8px;
  /* position: relative; */
  // background: url('../../assets/loginImage/tmj_login_bg_0530.webp');
  // background-size: 100% 100%;
  video {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  .login-logo {
    position: absolute;
    top: 18px;
    left: 36px;
    display: flex;
    align-items: center;
    span {
      font-family: Microsoft YaHei;
      font-size: 24px;
      color: #ffffff;
      letter-spacing: 0;
      font-weight: 400;
      margin-left: 10px;
      margin-top: 6px;
    }
  }
  .login-box {
    position: absolute;
    top: 90px;
    right: 90px;
    z-index: 999;
    background: #2c313b;
    // background: rgba(19, 21, 24, 0.7);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    width: 360px;
    height: 422px;
    .i18n {
      position: absolute;
      top: 16px;
      right: 20px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #ffffff;
      text-align: center;
      font-weight: 400;
      display: flex;
      justify-content: space-between;
      width: 30px;
      // span {
      //   border: 1px solid;
      // }
    }
    .form-box {
      width: 100%;
      height: 320px;
      // border: 1px solid;
      .tabs ::v-deep {
        .el-tabs__nav-wrap::after {
          display: none;
        }
        .el-tabs__item {
          &.is-top {
            color: #badfee;
          }
          &.is-active {
            color: #fff;
          }
          &:hover {
            color: #fff;
          }
        }
        .el-tabs__active-bar.is-top {
          width: 30px !important;
          left: 15px;
        }
        .el-tabs__nav-wrap.is-top {
          display: flex;
          justify-content: center;
        }
      }
      .login-form::v-deep {
        width: 280px;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin: 0px auto;
        // justify-content: center;
        // align-items: center;
        // img {
        //   margin: 12px 0;
        //   width: 24px;
        //   height: 24px;
        //   border-radius: 2px;
        // }
        .scan-qrcode {
          margin: 0 auto;
          width: 218px;
          height: 261px;
          position: relative;
          text-align: center;
          .bg {
            z-index: -1;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
          }
          .content {
            h3 {
              font-size: 14px;
              font-family: "PingFangSC-Medium PingFang SC";
              font-weight: 500;
              color: #e3eeff;
              margin: 20px 0;
            }
            .code-img {
              padding-left: 35px;
              width: 140px;
              height: 140px;
              overflow: hidden;
              img {
                border: 4px solid #fff;
              }
            }
            h4 {
              margin-top: 20px;
              font-size: 10px;
              font-family: "PingFangSC-Regular PingFang SC";
              color: #aed1df;
            }
          }
        }

        .icon-denglu {
          background: #3c4049;
        }
        .icon-pwd {
          background: #0b111f;
        }
        .el-form-item {
          margin: 10px 0;
        }
        .login-username {
          .icon-a-mima {
            color: #1eb9fa;
          }
          .el-input__inner {
            height: 48px;
            border: none;
            padding-left: 50px;
            // background: #3c4049;
            background: #0b111f;
            border-radius: 0px;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            &::placeholder {
              opacity: 0.5;
              font-family: MicrosoftYaHei;
              font-size: 14px;
              color: #badfee;
              font-weight: 400;
            }
          }
        }
        .login-password {
          .el-input__inner {
            height: 48px;
            border: none;
            padding-left: 50px;
            background: #0b111f;
            border-radius: 0px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            &::placeholder {
              opacity: 0.5;
              font-family: MicrosoftYaHei;
              font-size: 14px;
              color: #badfee;
              font-weight: 400;
            }
          }
          .el-input__icon {
            height: 48px;
            line-height: 48px;
          }
        }
        .login-tag {
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #fffff6;
          font-weight: 400;
          text-align: center;
          height: 20px;
          line-height: 20px;
          margin-top: 20px;
        }
        .tag-color-true {
          color: #fffff6;
        }
        .tag-color-false {
          color: red;
        }

        .login-check {
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #fffff6;
          font-weight: 400;
          width: 100%;
          text-align: center;
          margin: 10px 0;
          .el-checkbox {
            margin-right: 10px;
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #badfee;
            font-weight: 400;
            .el-checkbox__label {
              color: #badfee;
            }
            .el-checkbox__inner {
              background: #132343;
              border: 1px solid #1eb9fa;
              border-radius: 2px;
            }
            .el-checkbox__inner::after {
              border: 2px solid #50abff; //对勾颜色
              border-left: 0;
              border-top: 0;
            }
          }
          div {
            margin-left: -10px;
            display: inline-block;
            span {
              text-decoration: none;
              font-family: MicrosoftYaHei;
              font-size: 14px;
              color: #1eb9fa;
              text-align: center;
              font-weight: 400;
              cursor: pointer;
            }
          }
        }

        .el-form-item {
          border: none;
          padding: 0px;
          margin-bottom: 6px;
        }
        // input框的前置图标大小
        .el-input__prefix {
          width: 48px;
          color: #1eb9fa;
        }
        .iconfont {
          font-size: 24px;
          width: 48px;
          height: 45px;
          padding-top: 3px;
          margin-left: -3px;
        }
        //登录按钮
        .login_button {
          background: #08a4e6;
          text-align: center;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #ffffff;
          font-weight: 400;
          width: 100%;
          height: 48px;
          margin-top: 6px;
          border-radius: 10px;
        }
      }
    }
    .qrcodeStyle {
      height: 100%;
      .scan-qrcode {
        margin-top: 28px !important;
      }
      .login-check {
        margin-top: 23px !important;
      }
    }
  }
  .copyright {
    position: absolute;
    font-size: 14px;
    color: #fff;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
#wx_login_container {
  text-align: center;
  .wx_login {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .label {
      cursor: pointer;
      color: #fff;
    }
    .icon {
      cursor: pointer;
      margin-top: 30px;
      font-size: 30px;
      color: #fff;
    }
  }
}
</style>
